<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import FormModal from "@/components/modals/form-modal";
import MessageModal from "@/components/modals/message-modal";
import PreviousButton from "@/components/buttons/previous-button";
import SaveButton from "@/components/buttons/save-button";
import Loading from '@/components/Loading.vue';

export default {
	components: {
		Loading,
		FormModal,
		MessageModal,
		PreviousButton,
		SaveButton,
	},
	props: {
		parentInfo: Object,
		serviceOptions: Array,
		subjectOptions: Array,
		subjectDurationOptions: Array,
		subjectFrequencyOptions: Array,
		tutorTypeOptions: Array,
	},
	data() {
		const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

		return {
			id: id,
			modeName,
			isChecked: false,
			data: {
				student_name: '',
				service_id: '',
				service_name: '',
				subject_name: '',
				subject_duration_id: '',
				subject_duration_name: '',
				subject_frequency_id: '',
				subject_frequency_name: '',
				tutor_type: '',
				preferred_budget: '',
				gender_preference: '',
				preferred_time: '',
				estimated_commencement_date: '',
			},
			serviceTitles: {
				1: {
					name: "Tuition",
					imageUrl: require("@/assets/images/icons/tuition.png"),
					durations: []
				},
				2: {
					name: "Early Childhood Education",
					imageUrl: require("@/assets/images/icons/ece.png"),
					durations: []
				},
				3: {
					name: "Early Intervention",
					imageUrl: require("@/assets/images/icons/early_intervention.png"),
					durations: []
				},
				4: {
					name: "Therapy and Counselling",
					imageUrl: require("@/assets/images/icons/tnc.png"),
					durations: []
				},
				5: {
					name: "Enrichment",
					imageUrl: require("@/assets/images/icons/enrichment.png"),
					durations: []
				}
			},
			subjectsOptions: [],
			durationOptions: [],
			frequencyOptions: [],
			tutorOptions: [],
			saveBtnDisabled: false,
			loading: false,
		};
	},
	computed: {
		serviceID() {
			return this.parentInfo.service_id;
		}
	},
	watch: {
		isChecked(checkedVal) {
			this.data.service_name = checkedVal;
		},
		parentInfo(newVal) {
			this.data.service_id = newVal.service_id;
			this.data.subject_name = newVal.subject_name;
		},
		serviceOptions(newVal) {
			Object.values(newVal).forEach(item => {
				Object.values(this.serviceTitles).forEach((serviceItem, serviceIndex) => {
					if (item.value == serviceIndex + 1) {
						serviceItem.subjects = item.subjects;
						serviceItem.durations = item.durations;
						serviceItem.frequency = item.frequency;
						serviceItem.teacher_type = item.teacher_type;
					}
				});
			});
		},
		subjectOptions(newVal) {
			this.subjectsOptions = newVal;
		},
		subjectDurationOptions(newVal) {
			this.durationOptions = newVal;
		},
		subjectFrequencyOptions(newVal) {
			this.frequencyOptions = newVal;
		},
		tutorTypeOptions(newVal) {
			this.tutorOptions = newVal;
		}
	},
	methods: {
		goToPrevious() {
			this.$emit('go-to-previous');
		},
		async save(formData) {
			this.errors = {};
			let result;
			this.saveBtnDisabled = true;

			if (this.modeName === 'Add') {
				this.saveBtnDisabled = false;
				window.alert('Please fill personal info first');
				this.goToPrevious();
			} else {
				const requestData = formData;
				console.log('RD', requestData)
				result = await axios.put(`${process.env.VUE_APP_APIURL}/assignment/${this.id}`, requestData, {
					headers: authHeader(),
				});
			}

			if (result) {
				if (result.data['code'] === 'success') {
					this.saveBtnDisabled = false;
					this.$refs.messageModal.showModal('Your record has been updated successfully');
				} else if (result.data['code'] === 'invalid_field') {
					this.saveBtnDisabled = false;
					this.errors = result.data['errors'];
				}
			}
		},
		async getServiceDetail(service_id) {
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/service/${service_id}`, {
                headers: authHeader(),
            });

            if (result && result.data['code'] === 'success') {
                const data = result.data['data']['service'];
				
				return data;
			}
		},
		async cancel() {
			this.$router.push({ name: "Parent Request" });
		},
		async showModalWithTitle(index, bool) {
			this.loading = true;
			const serviceTitles = this.serviceTitles[index];
			serviceTitles.id = parseInt(index);

console.log('PI', this.parentInfo)

			var details = {};
			if (bool) {
				details = this.parentInfo;
				console.log(this.parentInfo.tutor_type_id);
			} else {
				details = {
					subject_id: '',
					subject_name: '',
					subject_duration_id: '',
					subject_duration_name: '',
					subject_frequency_id: '',
					subject_frequency_name: '',
					tutor_type_id: '[]',
					tutor_type: '',
					tutor_rate: '',
					preferred_budget: '',
					gender_preference: '',
					preferred_time: '',
					estimated_commencement_date: '',
				};
			}

			const serviceDetails = await this.getServiceDetail(index);
			if (serviceDetails) {
				console.log('trueeee')
				details.rate_settings = JSON.parse(serviceDetails['rate_settings']);
			}

			details.student_grade_id = this.parentInfo.student_grade_id ?? '';

console.log('TO', this.tutorOptions)
			this.$refs.formModal.showModal(serviceTitles, this.subjectsOptions, this.durationOptions, this.frequencyOptions, this.tutorOptions, details);
			this.loading = false;
		},
	},
};
</script>

<template>
	<div>
		<Loading :isLoading="loading" />
		<MessageModal ref="messageModal" />
		<FormModal ref="formModal" @save="save" />

		<div class="card" style="border-radius: 0;">
			<div class="card-body p-4" stlye="box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);">
				<div class="row mb-5">
					<div class="col-12">
						<label class="label">
							Choose the service that best matches the requestor's needs. A form will appear to complete the session details.
						</label>
					</div>
					<div class="col-12 col-md-3 mt-3" v-for="(service, index) in serviceTitles" :key="index">
						<label class="session__checkbox" :class="{ 'checked': serviceID == index }">
							<input type="radio" name="service" :value="service.name" :checked="isChecked === index" v-model="isChecked" @click="showModalWithTitle(index, (serviceID == index ? true : false))">
							<div class="d-flex align-items-center" style="flex-direction: column; gap: 5px;">
								<img :src="service.imageUrl" :alt="service.name">
								<span>{{ service.name }}</span>
							</div>
						</label>
					</div>
				</div>

				<div class="divider"></div>

				<div class="row mt-2">
					<div class="col-12" style="text-align: right;">
						<PreviousButton @click="goToPrevious" />
						<SaveButton @click="save" :text="'Save and continue'" :disabled="saveBtnDisabled" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
label.session__checkbox {
	cursor: pointer;
}

label.session__checkbox input {
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
	pointer-events: none;
}

label.session__checkbox div {
	display: inline-block;
	color: #505D69;
	padding: 24px 10px;
}

label.session__checkbox.checked {
	border: 1px solid #38B6FF;
	background-color: #38B6FF1A;
	border-radius: 6px;
}

label.session__checkbox span {
	color: #505D69;
	font-weight: 400;
	font-size: 14px;
	text-align: center
}

label.session__checkbox img {
	width: 70px;
}
</style>