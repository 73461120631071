<script>
import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";
import MultiSelectGroup from "@/components/forms/multiselect-group";

export default {
    components: {
		InputGroup,
        SelectGroup,
        MultiSelectGroup,
	},
    props: {
        service: Object,
    },
    data() {
        return {
            title: '',
            show: false,
            service_id: 0,
            service_name: '',
            preferredBudgetOption: '',
            formFields: {
                subject_id: {
                    value: '',
                    modified: false
                },
                subject_name: {
                    value: '',
                    modified: false
                },
                other_subject_name: {
                    value: '',
                    modified: false
                },
                subject_duration_id: {
                    value: '',
                    modified: false
                },
                subject_duration_name: {
                    value: '',
                    modified: false
                },
                other_subject_duration_name: {
                    value: '',
                    modified: false
                },
                subject_frequency_id: {
                    value: '',
                    modified: false
                },
                subject_frequency_name: {
                    value: '',
                    modified: false
                },
                other_subject_frequency_name: {
                    value: '',
                    modified: false
                },
                tutor_type_id: {
                    value: [],
                    modified: false
                },
                tutor_type: {
                    value: '',
                    modified: false
                },
                tutor_rate: {
                    value: '',
                    modified: false
                },
                other_tutor_type: {
                    value: '',
                    modified: false
                },
                preferred_tutor_rate: {
                    value: '',
                    modified: false
                },
                preferred_budget: {
                    value: 0,
                    modified: true
                },
                gender_preference: {
                    value: '',
                    modified: false
                },
                preferred_time: {
                    value: '',
                    modified: false
                },
                estimated_commencement_date: {
                    value: '',
                    modified: false
                }
            },
            rate_settings: '',
            tutor_type_ids: [],
            subjectOptions: [],
            durationOptions: [],
            frequencyOptions: [],
            tutorOptions: [],
            genderOptions: [
                { name: 'No Preference', value: 'no_preference' },
                { name: 'Male', value: 'male' },
                { name: 'Female', value: 'female' },
            ],
            errors: {},
        };
    },
    computed: {
        subjectNameInput() {
            return this.formFields.subject_id.value;
        },
        subjectDurationNameInput() {
			return this.formFields.subject_duration_id.value;
		},
        subjectFrequencyNameInput() {
			return this.formFields.subject_frequency_id.value;
		},
        tutorTypeInput() {
			return this.formFields.tutor_type_id.value;
		},
        tutorRateInput() {
			return this.formFields.tutor_rate.value;
		},
        preferredTutorRateInput() {
			return this.formFields.preferred_tutor_rate.value;
		},
        otherTutorTypeInput() {
            return this.formFields.other_tutor_type.value;
        },
        preferredBudgetInput() {
			return this.formFields.preferred_budget.value;
		},
        genderPreferenceInput() {
			return this.formFields.gender_preference.value;
		},
        preferredTimeInput() {
			return this.formFields.preferred_time.value;
		},
        commencementDateInput() {
			return this.formFields.estimated_commencement_date.value;
		},
        isOnlyOthersSelected() {
            return this.formFields.tutor_type_id.value.length === 1 && this.formFields.tutor_type_id.value.includes('others');
        }
    },
    methods: {
        sanitizeTitle(text) {
            return text.toLowerCase().replace(/\s+/g, '_');
        },
        updateFormField(key, event) {
            this.formFields[key].value = event;
            this.formFields[key].modified = true;

            if (key == 'subject_id') {
                const selectedSubject = this.subjectOptions.find(option => option.value == event);
                if (selectedSubject) {
                    this.formFields.subject_name.modified = true;
                    this.formFields.subject_name.value = selectedSubject.name;
                }
            }
            
            if (key == 'subject_duration_id') {
                const selectedDuration = this.durationOptions.find(option => option.value == event);
                if (selectedDuration) {
                    this.formFields.subject_duration_name.modified = true;
                    this.formFields.subject_duration_name.value = selectedDuration.name;
                }
            }

            if (key == 'subject_frequency_id') {
                const selectedFrequency = this.frequencyOptions.find(option => option.value == event);
                if (selectedFrequency) {
                    this.formFields.subject_frequency_name.modified = true;
                    this.formFields.subject_frequency_name.value = selectedFrequency.name;
                }
            }

            if (key == 'tutor_type_id') {
                const selectedTutors = this.tutorOptions.filter(option => event.includes(option.value));
                this.formFields.tutor_type.modified = true;
                this.formFields.tutor_type.value = selectedTutors.map(tutor => tutor.name).join(', ');
console.log('tutor_type_id modified')
                this.formFields.tutor_rate.modified = true;
                this.formFields.tutor_rate.value = selectedTutors.map(tutor => tutor.rate).join(', ');
            }

            // if (key == 'tutor_type_id') {
            //     const selectedTutor = this.tutorOptions.find(option => option.value == event);
            //     if (selectedTutor) {
            //         this.formFields.tutor_type.modified = true;
            //         this.formFields.tutor_type.value = selectedTutor.name;

            //         this.formFields.tutor_rate.modified = true;
            //         this.formFields.tutor_rate.value = selectedTutor.rate;
            //     }
            // }

            if (key == 'preferred_time') {
                this.formFields[key].value = event.target.value;
            }
        },
        updateBudget(value) {
            this.formFields.preferred_budget.value = value;
		},
        showModal(options, filteredSubjectOptions, filteredDurationOptions, filteredFrequencyOptions, filteredTutorOptions, details) {
            this.show = true;
            this.errors = {};
            this.title = options.name;
            this.service_id = options.id;
console.log('filteredTutorOptions', filteredTutorOptions)
console.log('details111', details)
            this.formFields.subject_id.value = details.subject_id;
            this.formFields.subject_name.value = details.subject_name;
            this.formFields.subject_duration_id.value = details.subject_duration_id;
            this.formFields.subject_duration_name.value = details.subject_duration_name;
            this.formFields.subject_frequency_id.value = details.subject_frequency_id;
            this.formFields.subject_frequency_name.value = details.subject_frequency_name;
            this.formFields.tutor_type_id.value = details.tutor_type_id;
            this.formFields.tutor_type.value = details.tutor_type;
            this.formFields.tutor_rate.value = details.tutor_rate;
            this.formFields.other_tutor_type.value = details.other_tutor_type;
            this.formFields.estimated_commencement_date.value = details.estimated_commencement_date;
            this.rate_settings = details.rate_settings;

            if (details.tutor_rate.includes('[')) {
                this.formFields.preferred_tutor_rate.value = 0;
            } else {
                this.formFields.preferred_tutor_rate.value = details.tutor_rate;
            }
            
            this.tutor_type_ids = details.tutor_type_id;

            if (details.other_tutor_type && this.formFields.tutor_type_id.value) {
                if (this.formFields.tutor_type_id.value != '[]') {
                    let tt_ids = this.formFields.tutor_type_id.value.match(/\d+/g).map(Number);
                    tt_ids.push("others");
                    this.tutor_type_ids = tt_ids;
                    this.formFields.tutor_type_id.value = tt_ids;
                } else {
                    this.tutor_type_ids = '["others"]';
                    this.formFields.tutor_type_id.value = '["others"]';
                }
            } else if (details.other_tutor_type) {
                this.tutor_type_ids = '["others"]';
                this.formFields.tutor_type_id.value = '["others"]';
            }

            this.formFields.preferred_budget.value = details.preferred_budget;
            if (details.preferred_budget == '') {
                this.formFields.preferred_budget.value = 0;
            }
            
            this.formFields.gender_preference.value = details.gender_preference;
            this.formFields.preferred_time.value = details.preferred_time;
        
            if (details.preferred_budget == 0) {
                this.preferredBudgetOption = 0;
            } else if (details.preferred_budget == 1) {
                this.preferredBudgetOption = 1;
            } else if (details.preferred_budget == 2) {
                this.preferredBudgetOption = 2;
            }
            
            if (options.subjects) {
                const serviceSubjects = options.subjects;
                filteredSubjectOptions = filteredSubjectOptions.filter(option => serviceSubjects.includes(String(option.value)));
                this.subjectOptions = filteredSubjectOptions;

                this.subjectOptions.push({
                    'name': 'Others, please specify',
                    'value': 'others'
                });
            }

            if (options.durations) {
                const serviceDurations = JSON.parse(options.durations);                
                filteredDurationOptions = filteredDurationOptions.filter(option => serviceDurations.includes(option.value));
                this.durationOptions = filteredDurationOptions;

                this.durationOptions.push({
                    'name': 'Others, please specify',
                    'value': 'others'
                });
            }

            if (options.frequency) {
                const serviceFrequency = JSON.parse(options.frequency);
                
                filteredFrequencyOptions = filteredFrequencyOptions.filter(option => serviceFrequency.includes(option.value));
                this.frequencyOptions = filteredFrequencyOptions;

                this.frequencyOptions.push({
                    'name': 'Others, please specify',
                    'value': 'others'
                });
            }

            if (options.teacher_type) {
                const serviceTutors = JSON.parse(options.teacher_type);
                filteredTutorOptions = filteredTutorOptions.filter(option => serviceTutors.includes(option.value));
                this.tutorOptions = filteredTutorOptions.map(option => {
                    return {
                        ...option,
                        name: `${option.name} (${option.rate})`,
                        original_name: option.name,
                        rate: option.rate,
                    };
                });

                console.log('serviceTutors', this.tutorOptions)

                this.tutorOptions.push({
                    'name': 'Others',
                    'value': 'others'
                });

                if (this.rate_settings) {
                    this.tutorOptions = this.tutorOptions.map(tutorOption => {
                        console.log('details.student_grade_id', details.student_grade_id)
                        const matchingTutor = this.rate_settings.find(rateSetting => rateSetting.tutorType == tutorOption.value && rateSetting.studentGradeCategory == details.student_grade_id);

                        if (matchingTutor) {
                            const updatedName = `${tutorOption.original_name} (${matchingTutor.rate})`;
                            console.log('Updating name from', tutorOption.original_name, 'to', updatedName);

                            return {
                                ...tutorOption,
                                name: updatedName,  // Update the name with the matching rate
                                rate: matchingTutor.rate
                            };
                        }

                        return tutorOption;
                    });
                }
            }
            
            if (this.formFields.subject_id.value == null) {
                this.formFields.subject_id.value = 'others';
                this.formFields.other_subject_name.value = details.subject_name;
            }

            if (this.formFields.subject_duration_id.value == null) {
                this.formFields.subject_duration_id.value = 'others';
                this.formFields.other_subject_duration_name.value = details.subject_duration_name;
            }

            if (this.formFields.subject_frequency_id.value == null) {
                this.formFields.subject_frequency_id.value = 'others';
                this.formFields.other_subject_frequency_name.value = details.subject_frequency_name;
            }

console.log('details', details)
console.log('formFields', this.formFields)
        },
        closeModal() {
            this.show = false;
        },
        saveData() {
            const formData = {};
            let hasError = false;
    console.log('formFields', this.formFields)
            let fieldDisplayName = {
                'subject_id': 'Subject is required',
                'subject_duration_id': 'Subject Duration is required',
                'subject_frequency_id': 'Subject Frequency is required',
                'tutor_type_id': 'Please select at least one',
                'tutor_type': 'Please select at least one',
                'preferred_budget': 'Please select one',
                'preferred_tutor_rate': 'Please enter preferred budget',
                'gender_preference': 'Please select one',
                'preferred_time': 'Please fill in your available time',
                'estimated_commencement_date': 'Please select a date',
            };

            this.errors = {};
            let bypassFields = ['other_tutor_type', 'tutor_rate', 'preferred_tutor_rate', 'other_subject_name', 'other_subject_duration_name', 'other_subject_frequency_name'];

            Object.keys(this.formFields).forEach(key => {
                if (!bypassFields.includes(key)) {
                    if (this.formFields[key].value === '' || this.formFields[key].value === null || this.formFields[key].value === undefined) {
                        console.log('KEY', key)
                        console.log('this.formFields[key].value', this.formFields[key].value)
                        Object.entries(fieldDisplayName).forEach(([displayKey, displayValue]) => {
                            if (displayKey == key) {
                                console.log('displayKey', displayKey)
                                this.errors[key] = displayValue;
                                console.log('errors', this.errors[key])
                            }
                        });
                        hasError = true;
                    }

                    if (this.formFields[key].modified) {
                        formData[key] = this.formFields[key].value;
                    }
                }
            });

            if (this.subjectNameInput == 'others') {
                if (this.formFields.other_subject_name.value == '' || this.formFields.other_subject_name.value == undefined) {
                    hasError = true;
                    this.errors.other_subject_name = 'Please enter request';
                } else {
                    formData.subject_id = null;
                    this.formFields.subject_name.value = this.formFields.other_subject_name.value;
                    formData.subject_name = this.formFields.other_subject_name.value;
                }
            }

            if (this.subjectDurationNameInput == 'others') {
                if (this.formFields.other_subject_duration_name.value == '' || this.formFields.other_subject_duration_name.value == undefined) {
                    hasError = true;
                    this.errors.other_subject_duration_name = 'Please enter duration';
                } else {
                    formData.subject_duration_id = null;
                    this.formFields.subject_duration_name.value = this.formFields.other_subject_duration_name.value;
                }
            }

            if (this.subjectFrequencyNameInput == 'others') {
                if (this.formFields.other_subject_frequency_name.value == '' || this.formFields.other_subject_frequency_name.value == undefined) {
                    hasError = true;
                    this.errors.other_subject_frequency_name = 'Please enter frequency';
                } else {
                    formData.subject_frequency_id = null;
                    this.formFields.subject_frequency_name.value = this.formFields.other_subject_frequency_name.value;
                }
            }

            if (this.formFields.preferred_budget.value == 1) {
                if (this.formFields.preferred_tutor_rate.value == '' || this.formFields.preferred_tutor_rate.value == null || this.formFields.preferred_tutor_rate.value == undefined) {
                    hasError = true;
                    this.errors.preferred_tutor_rate = 'Please enter preferred budget';
                }
            }

            if (this.formFields.tutor_type_id.value.includes('others')) {
                if (this.formFields.other_tutor_type.value == '' || this.formFields.other_tutor_type.value == null || this.formFields.other_tutor_type.value == undefined) {
                    hasError = true;
                    this.errors.other_tutor_type = 'Please enter service provider';
                }
            }

            if (this.formFields.preferred_budget.value == 2 && !this.formFields.tutor_type_id.value.includes('others')) {
                hasError = true;
                this.errors.preferred_budget = 'Please select one';
            } else if (this.formFields.preferred_budget.value == 0 && (this.formFields.tutor_type_id.value.length == 1 && this.formFields.tutor_type_id.value.includes('others'))) {
                hasError = true;
                this.errors.preferred_budget = 'Please select one';
            }

            if (hasError) {
                console.log('Validation errors', this.errors);
                return;
            }

            formData.other_tutor_type = this.formFields.other_tutor_type.value;

            if (!formData.preferred_budget || formData.preferred_budget == '') {
                formData.preferred_budget = 0;
            }

            if (this.formFields.preferred_budget.value == 1) {                
                formData.tutor_rate = this.formFields.preferred_tutor_rate.value;
            } else {
                const selectedTutor = this.tutorOptions.find(option => option.value == this.formFields.tutor_type_id.value);
                if (selectedTutor) {
                    formData.tutor_rate = selectedTutor.rate;
                }
            }
            delete formData.preferred_tutor_rate;

            formData.subject_duration_name = this.formFields.subject_duration_name.value.split(' ')[0].trim();
            formData.subject_frequency_name = this.formFields.subject_frequency_name.value.split(' ')[0].trim();
            formData.tutor_type = this.formFields.tutor_type.value.split('(')[0].trim();
            formData.tutor_type_id = this.formFields.tutor_type_id.value;
console.log('TTI', this.tutor_type_ids);

            formData.service_id = this.service_id;
            formData.service_name = this.title;
console.log('FD', formData)
console.log('TFF', this.formFields)
            this.$emit('save', formData);
            this.closeModal();
        }
    },
    watch: {
        subjectNameInput(value) {
            if (this.formFields.subject_id.value && value !== this.formFields.subject_id.value) {
                this.formFields.subject_id.modified = true;
            }
        },
        subjectDurationNameInput(value) {
            if (this.formFields.subject_duration_id.value && value !== this.formFields.subject_duration_id.value) {
                this.formFields.subject_duration_id.modified = true;
            }
        },
        subjectFrequencyNameInput(value) {
            if (this.formFields.subject_frequency_id.value && value !== this.formFields.subject_frequency_id.value) {
                this.formFields.subject_frequency_id.modified = true;
            }
        },
        tutorTypeInput(value) {
            console.log('value', value)
            if (this.formFields.tutor_type_id.value && value !== this.formFields.tutor_type_id.value) {
                this.formFields.tutor_type_id.modified = true;
            }
        },
        tutorRateInput(value) {
            if (this.formFields.tutor_rate.value && value !== this.formFields.tutor_rate.value) {
                this.formFields.tutor_rate.modified = true;
            }
        },
        preferredTutorRateInput(value) {
            if (this.formFields.preferred_tutor_rate.value && value !== this.formFields.preferred_tutor_rate.value) {
                this.formFields.preferred_tutor_rate.modified = true;
            }
        },
        otherTutorTypeInput(value) {
            if (this.formFields.other_tutor_type.value && value !== this.formFields.other_tutor_type.value) {
                this.formFields.other_tutor_type.modified = true;
            }
        },
        preferredBudgetInput(value) {
            if (this.formFields.preferred_budget.value && value !== this.formFields.preferred_budget.value) {
                this.formFields.preferred_budget.modified = true;
            }
        },
        preferredTimeInput(value) {
            if (this.formFields.preferred_time.value && value !== this.formFields.preferred_time.value) {
                this.formFields.preferred_time.modified = true;
            }
        },
        commencementDateInput(value) {
            if (this.formFields.estimated_commencement_date.value && value !== this.formFields.estimated_commencement_date.value) {
                this.formFields.estimated_commencement_date.modified = true;
            }
        }
    }
};
</script>

<template>
    <b-modal v-model="show" size="lg" scrollable hide-footer hide-header>
        <div class="modal__content">
            <p class="modal__title">{{ title }}</p>
            <p class="modal__subtitle">Please enter the details of request.</p>
            
            <form @submit.prevent="saveData">
                <div class="form-group">
                    <SelectGroup v-if="service_id == 1 || service_id == 2" id="subject_id" displayName="Subjects" :value="subjectNameInput" @input="updateFormField('subject_id', $event)" :options="subjectOptions" />
                    <SelectGroup v-if="service_id == 3 || service_id == 4" id="subject_id" displayName="Therapy services" :value="subjectNameInput" @input="updateFormField('subject_id', $event)" :options="subjectOptions" />
                    <SelectGroup v-if="service_id == 5" id="subject_id" displayName="Enrichment services" :value="subjectNameInput" @input="updateFormField('subject_id', $event)" :options="subjectOptions" />
                
                    <InputGroup v-if="subjectNameInput == 'others'" type="text" id="other_subject_name" :value="formFields.other_subject_name.value" displayName="Please specify" @input="updateFormField('other_subject_name', $event)" />
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.subject_id">
                    {{ errors.subject_id }}
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.other_subject_name">
                    {{ errors.other_subject_name }}
                </div>

                <div class="form-group">
                    <SelectGroup v-if="service_id == 1 || service_id == 2" id="subject_duration_id" displayName="Duration per subject" :value="subjectDurationNameInput" @input="updateFormField('subject_duration_id', $event)" :options="durationOptions" />
                    <SelectGroup v-if="service_id == 3 || service_id == 4" id="subject_duration_id" displayName="Duration per type of therapy" :value="subjectDurationNameInput" @input="updateFormField('subject_duration_id', $event)" :options="durationOptions" />
                    <SelectGroup v-if="service_id == 5" id="subject_duration_id" displayName="Duration per type of enrichment" :value="subjectDurationNameInput" @input="updateFormField('subject_duration_id', $event)" :options="durationOptions" />
                
                    <InputGroup v-if="subjectDurationNameInput == 'others'" type="text" id="other_subject_duration_name" :value="formFields.other_subject_duration_name.value" displayName="Please specify" @input="updateFormField('other_subject_duration_name', $event)" />
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.subject_duration_id">
                    {{ errors.subject_duration_id }}
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.other_subject_duration_name">
                    {{ errors.other_subject_duration_name }}
                </div>

                <div class="form-group">
                    <SelectGroup v-if="service_id == 1 || service_id == 2" id="subject_frequency_id" displayName="Frequency per subject" :value="subjectFrequencyNameInput" @input="updateFormField('subject_frequency_id', $event)" :options="frequencyOptions" />
                    <SelectGroup v-else-if="service_id == 3 || service_id == 4" id="subject_frequency_id" displayName="Frequency per type of therapy" :value="subjectFrequencyNameInput" @input="updateFormField('subject_frequency_id', $event)" :options="frequencyOptions" />
                    <SelectGroup v-else-if="service_id == 5" id="subject_frequency_id" displayName="Frequency per type of enrichment" :value="subjectFrequencyNameInput" @input="updateFormField('subject_frequency_id', $event)" :options="frequencyOptions" />
                
                    <InputGroup v-if="subjectFrequencyNameInput == 'others'" type="text" id="other_subject_frequency_name" :value="formFields.other_subject_frequency_name.value" displayName="Please specify" @input="updateFormField('other_subject_frequency_name', $event)" />
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.subject_frequency_id">
                    {{ errors.subject_frequency_id }}
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.other_subject_frequency_name">
                    {{ errors.other_subject_frequency_name }}
                </div>

                <div class="form-group">
                    <MultiSelectGroup v-if="service_id == 1 || service_id == 2" id="tutor_type" displayName="Tutor types (Include rates)" :value="tutor_type_ids" @input="updateFormField('tutor_type_id', $event)" :options="tutorOptions" />
                    <MultiSelectGroup v-else-if="service_id == 3 || service_id == 4" id="tutor_type" displayName="Service professionals (Include rates)" :value="tutor_type_ids" @input="updateFormField('tutor_type_id', $event)" :options="tutorOptions" />
                    <MultiSelectGroup v-else-if="service_id == 5" id="tutor_type" displayName="Enrichment specialists (Include rates)" :value="tutor_type_ids" @input="updateFormField('tutor_type_id', $event)" :options="tutorOptions" />
                    <input type="hidden" :value="tutorRateInput">
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.tutor_type">
                    {{ errors.tutor_type }}
                </div>

                <div class="form-group" v-if="formFields.tutor_type_id.value.includes('others')">
                    <InputGroup type="text" id="other_service_name" displayName="Please specify service provider" :value="otherTutorTypeInput" @input="updateFormField('other_tutor_type', $event)" />
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.other_tutor_type">
                    {{ errors.other_tutor_type }}
                </div>

                <div class="form-group">
                    <label>Please select</label>
                    <div class="modal-checkbox__row">
                        <div class="radio" v-if="!isOnlyOthersSelected">
                            <input type="radio" id="agree" value="0" name="preferred_budget" role="button" :checked="formFields.preferred_budget.value == 0" v-model="preferredBudgetOption" @change="updateBudget($event.target.checked ? 0 : '')" @input="updateFormField('preferred_budget', $event)">
                            <label class="mb-0 ml-2" for="agree" role="button">I am agreeable to the market rates above</label>
                        </div>
                        
                        <div class="radio">
                            <input type="radio" id="not_agree" value="1" name="preferred_budget" role="button" :checked="formFields.preferred_budget.value == 1" v-model="preferredBudgetOption" @change="updateBudget($event.target.checked ? 1 : '')" @input="updateFormField('preferred_budget', $event)"> 
                            <label class="mb-0 ml-2" for="not_agree" role="button">I have a preferred budget</label>
                        </div>

                        <div class="radio" v-if="formFields.tutor_type_id.value.includes('others')">
                            <input type="radio" id="quote_by_sp" value="2" name="preferred_budget" role="button" :checked="formFields.preferred_budget.value == 2" v-model="preferredBudgetOption" @change="updateBudget($event.target.checked ? 2 : '')" @input="updateFormField('preferred_budget', $event)">
                            <label class="mb-0 ml-2" for="quote_by_sp" role="button">To be quoted by Service Providers</label>
                        </div>
                    </div>
                </div>
                
                <div class="form-group" v-if="formFields.preferred_budget.value == 1">
                    <InputGroup type="number" id="preferred_budget" displayName="Preferred budget" :value="preferredTutorRateInput" @input="updateFormField('preferred_tutor_rate', $event)" />
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.preferred_tutor_rate">
                    {{ errors.preferred_tutor_rate }}
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.preferred_budget">
                    {{ errors.preferred_budget }}
                </div>

                <div class="form-group">
                    <SelectGroup id="gender_preference" displayName="Gender Preference" :value="genderPreferenceInput" @input="updateFormField('gender_preference', $event)" :options="genderOptions" />
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.gender_preference">
                    {{ errors.gender_preference }}
                </div>

                <div class="form-group">
                    <label for="preferred_time">All available days and timings</label>
                    <textarea class="form-control" id="preferred_time" rows="5" :value="preferredTimeInput" @input="updateFormField('preferred_time', $event)"></textarea>
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.preferred_time">
                    {{ errors.preferred_time }}
                </div>

                <div class="form-group">
                    <InputGroup type="date" id="estimated_commencement_date" displayName="Estimated commencement date" :value="commencementDateInput" @input="updateFormField('estimated_commencement_date', $event)" />
                </div>
                <div class="alert alert-danger mt-3" role="alert" v-if="errors.estimated_commencement_date">
                    {{ errors.estimated_commencement_date }}
                </div>

                <div class="modal-footer">
                    <button type="button" class="cancel__btn" @click="closeModal">Cancel</button>
                    <button type="submit" class="save__btn">Save and continue</button>
                </div>
            </form>
        </div>        
    </b-modal>
</template>

<style scoped>
.modal-checkbox__row {
    display: grid;
    align-items: center;
    gap: 10px;

    div {
        display: flex;
        align-items: center;
    }
}

.modal-footer {
    padding-top: 24px;
    padding-left: 0;
    padding-right: 0;
    margin-top: 48px;
    border-top: 1px solid rgba(205, 205, 205, 0.5);
}
    .cancel__btn {
        border: 1px solid #505D69;
        border-radius: 6px;
        background: #fff;
        padding: 9px 20px;
        font-family: Nunito;
        font-weight: 600;
        font-size: 14px;
        color: #505D69;
    }

    .save__btn {
        border: 1px solid #004AAD;
        border-radius: 6px;
        background: #004AAD;
        padding: 9px 20px;
        font-family: Nunito;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
    }
</style>