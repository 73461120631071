<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Loading from '@/components/Loading.vue';
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import TabPersonalInfo from "@/views/pages/parent_request/tabs-personal-info";
import TabSessionDetails from "@/views/pages/parent_request/tabs-session-details";
import TabRemarks from "@/views/pages/parent_request/tabs-remarks";

export default {
	components: {
		Loading,
		Layout,
		PageHeader,
		ConfirmationModal,
		MessageModal,
		TabPersonalInfo,
		TabSessionDetails,
		TabRemarks
	},
	data() {
		const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

		return {
			id: id,
			title: `${modeName} Parent Request`,
			modeName,
			items: [
				{
					text: "Parent request"
				},
				{
					text: `${modeName}`,
					active: true,
				},
			],
			data: {
				requestor_name: '',
				phone: '',
				email: '',
				requestor_type: '',
				postal: '',
				type_of_lesson: '',
				student_name: '',
				student_level: '',
				other_student_level: '',
				status: '',
				application_status: '',
				service_id: '',
				service_name: '',
				subject_id: '',
				subject_name: '',
				subject_duration_id:'',
				subject_duration_name:'',
				subject_frequency_id:'',
				subject_frequency_name:'',
				tutor_type_id:'',
				tutor_type:'',
				tutor_rate: '',
				other_tutor_type: '',
				preferred_budget: '',
				gender_preference: '',
				preferred_time: '',
				remarks: '',
				assigned_tutor_email: '',
				assigned_date: '',
				address: '',
				unit: '',
				telegram_channel_id: '',
				student_grade_id: '',
				estimated_commencement_date: '',
			},
			gradeOptions: [],
			serviceSubjectOptions: [],
			serviceOptions: [],
			subjectOptions: [],
			subjectDurationOptions: [],
			subjectFrequencyOptions: [],
			tutorTypeOptions: [],
			errors: {},
			activeTab: 0,
			loading: true,
		};
	},
	async mounted() {
		this.retrieveGrades().then((grades) => {
			this.gradeOptions = grades.map((grade) => ({
				name: grade.name,
				value: grade.name,
			}));

			this.gradeOptions.push({
				name: 'Others, please specify',
				value: 'others'
			});
		});

		this.serviceSubjectOptions = await this.retrieveServiceSubjects();

        const services = await this.retrieveServices();
        
        this.serviceOptions = services.map((service) => {
            const subjects = [];

            this.serviceSubjectOptions.forEach((subjectOption) => {
                if (subjectOption.service_id == service.id) {
                    subjects.push(subjectOption.subject_id);
                }
            });

            return {
                name: service.name,
                value: service.id,
                subjects: subjects,
                durations: service.durations,
                frequency: service.frequency,
                teacher_type: service.teacher_type
            };
        });

		this.retrieveSubjects().then((subjects) => {
			this.subjectOptions = subjects.map((subject) => ({
				name: subject.name,
				value: subject.id,
			}));
		});

		this.retrieveSubjectDurations().then((durations) => {
			this.subjectDurationOptions = durations.map((duration) => ({
				name: duration.duration + ' minutes',
				value: duration.id,
			}));
		});

		this.retrieveSubjectFrequency().then((frequencies) => {
			this.subjectFrequencyOptions = frequencies.map((frequency) => ({
				name: frequency.frequency + (frequency.frequency > 1 ? ' lessons/week' : ' lesson/week'),
				value: frequency.id,
			}));
		});

		this.retrieveTutorTypes().then((types) => {
			this.tutorTypeOptions = types.map((type) => ({
				name: type.name,
				rate: type.rate,
				value: type.id,
			}));
		});

		if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/assignment/${this.id}`, {
                headers: authHeader(),
            });

            if (result && result.data['code'] === 'success') {
                const data = result.data['data']['assignment'];

                for (const [key, item] of Object.entries(data)) {
                    if (key in this.data) {
						if (key !== 'items') {
							this.data[key] = item;
						}
                    }
                }

				const nameNotFound = this.gradeOptions.every(item => item.value !== this.data.student_level);
				if (nameNotFound) {
					this.data.other_student_level = this.data.student_level;
					this.data.student_level = 'others';
				}
				console.log('nameNotFound', nameNotFound)

				const item = data.items[0];
				if (item) {
					for (const [key, value] of Object.entries(item)) {
						if (key in this.data) {
							this.data[key] = value;
						}

						if (key == 'status') {
							this.data.application_status = value;
						}
					}
				}
            }
        }

		this.loading = false;
	},
	methods: {
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		handleTabChange(tabIndex) {
			this.activeTab = tabIndex;
		},
		async retrieveGrades() {
            let grades = [];
			let filter = {
				sort_order: 'asc',
				sort: 'name'
			};

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/student_grade`, {
                    headers: authHeader(),
					params: filter
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const gradeDetails = result['data'].student_grades;
                    grades = gradeDetails.filter(grade => grade.status == 1);
                }
            } catch (e) {
                console.error(e);
            }

            return grades;
        },

		async retrieveServiceSubjects() {
            let serviceSubjects = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/serviceSubject`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    serviceSubjects = result['data'];
                }
            } catch (e) {
                console.error(e);
            }

            return serviceSubjects;
        },

		async retrieveServices() {
            let services = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/service`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const serviceDetails = result['data'].services;
                    services = serviceDetails.filter(service => service.status == 1);
                }
            } catch (e) {
                console.error(e);
            }

            return services;
        },

		async retrieveSubjects() {
            let subjects = [];
			let filter = {
				sort_order: 'asc',
				sort: 'name'
			};

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/subject/all`, {
                    headers: authHeader(),
					params: filter
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const subjectDetails = result['data'];
                    subjects = subjectDetails.filter(subject => subject.status == 1);
                }
            } catch (e) {
                console.error(e);
            }

            return subjects;
        },

		async retrieveSubjectDurations() {
            let subject_durations = [];
			let filter = {
				sort_order: 'asc',
				sort: 'duration'
			};

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/subject_duration`, {
                    headers: authHeader(),
					params: filter
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const subjectDurationDetails = result['data'].subject_durations;
                    subject_durations = subjectDurationDetails.filter(duration => duration.status == 1);
                }
            } catch (e) {
                console.error(e);
            }

            return subject_durations;
        },

		async retrieveSubjectFrequency() {
            let subject_frequencies = [];
			let filter = {
				sort_order: 'asc',
				sort: 'frequency'
			};

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/subject_frequency`, {
					headers: authHeader(),
					params: filter
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const subjectFrequencyDetails = result['data'].subject_frequencys;
                    subject_frequencies = subjectFrequencyDetails.filter(frequency => frequency.status == 1);
					console.log('subject_frequencies', subject_frequencies)
                }
            } catch (e) {
                console.error(e);
            }

            return subject_frequencies;
        },

		async retrieveTutorTypes() {
            let tutor_types = [];
			let filter = {
				sort_order: 'asc',
				sort: 'name'
			};

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/tutor_type`, {
                    headers: authHeader(),
					params: filter
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const TutorTypeDetails = result['data'].tutor_types;
					
                    tutor_types = TutorTypeDetails.filter(type => type.status == 1);
					console.log('TT', tutor_types)
                }
            } catch (e) {
                console.error(e);
            }

            return tutor_types;
        },
	}
};
</script>

<template>
	<Layout>
		<Loading :isLoading="loading" />
		<PageHeader :title="title" :items="items" />
		<ConfirmationModal ref="confirmationModal" />
		<MessageModal ref="messageModal" />

		<b-tabs v-model="activeTab">
			<b-tab title="Personal Info">
				<TabPersonalInfo :parentInfo="this.data" :gradeOptions="gradeOptions" />
			</b-tab>

			<b-tab title="Session Details">
				<TabSessionDetails @go-to-previous="handleTabChange(0)" :parentInfo="this.data" :serviceOptions="serviceOptions" :subjectOptions="subjectOptions" :subjectDurationOptions="subjectDurationOptions" :subjectFrequencyOptions="subjectFrequencyOptions" :tutorTypeOptions="tutorTypeOptions" />
			</b-tab>

			<b-tab title="Remarks">
				<TabRemarks @go-to-first-tab="handleTabChange(0)" @go-to-previous="handleTabChange(1)" :parentInfo="this.data" :subjectOptions="subjectOptions" :subjectDurationOptions="subjectDurationOptions" :subjectFrequencyOptions="subjectFrequencyOptions" :tutorTypeOptions="tutorTypeOptions" />
			</b-tab>
		</b-tabs>
	</Layout>
</template>

<style scoped>
.card-body {
	padding: 36px 48px;

	& .card__title {
		color: #505D69;
		font-family: 'Inter';
		font-weight: 700;
		font-size: 18px;
		line-height: 27px;
		border-left: 5px solid #38B6FF;
		padding-left: 8px;
		margin-bottom: 36px;
	}

	& .label {
		font-family: Nunito;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		color: #505D69;
		margin-bottom: 0;
	}
	
	& .data {
		font-family: Nunito;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #505D69;
		margin-bottom: 0;
	}
}
</style>